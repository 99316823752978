import { get, put, Delete, post } from "../axios";

// 班级管理-班级列表
export const classClassPage = (params) =>
  get(`/web/class/class/classPage`, params);

// 班级管理-班级新增
export const classAddClass = (params) =>
  post(`/web/class/class/addClass`, params);

// 班级管理-班级详情
export const classClassView = (params) =>
  get(`/web/class/class/classView/` + params, "");

// 班级管理-班级删除
export const classDeleteClass = (params) =>
  Delete("/web/class/class/deleteClass/" + params, "");

//班级管理-班级编辑
export const classUpdateClass = (params) =>
  post("/web/class/class/updateClass", params);

//班级管理-班级学生列表
export const classClassStudent = (params) =>
  get("/web/class/class/classStudent/" + params, "");

//班级管理-复习进度
export const classReviewPage = (params) =>
  get("/web/class/class/reviewPage", params);

//用户管理-班主任下拉
export const classClassList = (params) =>
  get("/web/class/class/classList", params);
