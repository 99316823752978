<template>
  <div class="main-content">
    <avue-crud
      ref="crud"
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-input
                  v-model="searchData.studentName"
                  placeholder="学生姓名"
                  clearable
                  maxlength="50"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <!-- <span class="span marginRight10 width80 textAlignRight"> </span> -->
              <div class="width300">
                <el-select
                  v-model="searchData.classId"
                  clearable
                  placeholder="选择班级"
                >
                  <el-option
                    v-for="(item, index) in classList"
                    :key="index"
                    :label="item.className"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <div class="width300">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :clearable="true"
                >
                  <!-- @change="handleSearch" -->
                </el-date-picker>
              </div>
            </div>

            <el-button
              size="medium"
              type="primary"
              @click="handleSearch"
              style="margin-bottom: 10px"
              class="marginLeft10"
              >搜索</el-button
            >
            <el-button
              size="medium"
              type="info"
              @click="handleReset"
              style="margin-bottom: 10px"
              class="marginLeft10 marginRight10"
              >重置</el-button
            >
          </div>
        </div>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import { studentSignSignPage } from "@/api/user/level";
import { classClassList } from "@/api/user/class";
import dayjs from "dayjs";
export default {
  name: "index",
  data() {
    return {
      searchData: {},
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      showLoadingForm: false,
      dialogVisible: false,
      option: {
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        menu: false,
        editBtn: false,
        delBtn: false,
        tip: false,
        column: [
          {
            label: "学生名称",
            prop: "studentName",
          },
          {
            label: "班级名称",
            prop: "className",
          },
          {
            label: "打卡时间",
            prop: "createTime",
          },
        ],
      },
      dialogVisible: false,
      classList: [], //班级选择列表
    };
  },
  created() {
    this.getClass();
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      const searchData = {
        ...this.searchData,
      };
      if (this.searchData.createTime) {
        searchData.startTime = dayjs(this.searchData.createTime[0]).format(
          "YYYY-MM-DD 00:00:00"
        );
        searchData.endTime = dayjs(this.searchData.createTime[1]).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      delete searchData.createTime;
      studentSignSignPage({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },

    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      this.searchData = {
        state: "",
        name: "",
        createTime: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },

    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },
    //获取班级下拉列表
    getClass() {
      classClassList().then((res) => {
        if (res.code == 200) {
          this.classList = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.table {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table_item {
  margin-bottom: 10px;
  width: 30%;
  margin-right: 10px;
  text-align: center;
  font-size: 14px;
  display: flex;
  align-items: center;
  .table_label {
    width: 100px;
  }
}
.table_input {
  margin-right: 10px;
}
.table_add {
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
}
</style>
