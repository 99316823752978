import { get, put, Delete, post } from "../axios";

// 学生等级-列表
export const studentUserLevelPage = (params) =>
  get(`/web/student/user/levelPage`, params);

// 学生等级-新增
export const studentsStudentAddLevel = (params) =>
  post(`/web/student/student/addLevel`, params);

// 学生等级-详情
export const studentGetLevelView = (params) =>
  get(`/web/student/student/getLevelView/` + params, "");

// 学生等级-修改
export const studentUpdateLevel = (params) =>
  post(`/web/student/student/updateLevel`, params);

// 学生等级-删除
export const gatewayDelete = (params) =>
  Delete("/web/student/student/deleteLevel/" + params, "");

//学生等级-新增学习计划
export const studentLearnAddPlan = (params) =>
  post("/web/student/learn/addPlan", params);

//学生等级-下载文件模板
export const studentLearnExport = (params) =>
  get("/web/student/learn/export", params);

//学生等级-导入文件模板
export const studentPlanImportModele = (params) =>
  post("/web/student/plan/importModele", params);

//学生等级-学习计划详情
export const studentPlanPlanView = (params) =>
  post("/web/student/plan/planView/" + params, "");

//学生等级-学生等级下拉
export const studentLevelList = (params) =>
  get("/web/student/student/levelList", params);

//学生等级-打卡记录
export const studentSignSignPage = (params) =>
  get("/web/student/sign/signPage", params);
